import { Avatar, Card, Col, Row, Image, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import React, { Children, useEffect, useState } from "react";
import {
  ClockCircleFilled,
  CarryOutFilled,
  ReadFilled,
  ScheduleFilled,
  CreditCardFilled,
  IdcardFilled,
  MessageFilled,
  TrophyFilled,
} from "@ant-design/icons";
import { IDashboardTableCardProps } from "./DashboardTableCard.model";
import {
  currencyFormat,
  encrypt,
  openInNewTab,
} from "src/utils/helperFunction";
import {
  AdmisionStatusType,
  placementPortalRegistrationUrl,
} from "src/utils/constants/constant";
import { loginSelector } from "src/state/Login/login.reducer";
import { studentSelector } from "src/state/student/student.reducer";
import { useAppSelector } from "src/state/app.hooks";
import ModalComponent from "../Modal";
import { studentExamSelector } from "src/state/exam/exam.reducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import { getExamResultDetails } from "src/state/exam/exam.action";
import { useParams } from "react-router-dom";
import { IExamResultData } from "src/services/exam/exam.model";

const DashboardTableCard = (props: IDashboardTableCardProps) => {
  const {
    student_name,
    gr_id,
    status,
    fees,
    course,
    image_path,
    enrollment_number,
    careerCourse,
    student_performance,
    package_id,
  } = props;
  const loginState = useAppSelector(loginSelector);
  const studentState = useAppSelector(studentSelector);
  const studentExamState = useAppSelector(studentExamSelector);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [openCountModel, setOpenCountModel] = useState<boolean>(false);
  const [data, setData] = useState<IExamResultData>();

  const isStudentMobileNumber = () => {
    const loginMobileNumber = loginState?.verifyData?.data?.mobile_no;
    const studentData = studentState?.getById?.data[0];
    const fatherMobileNumber = studentData.father_mobile_no;
    const motherMobileNumber = studentData.mother_mobile_no;

    return !(
      loginMobileNumber === fatherMobileNumber ||
      loginMobileNumber === motherMobileNumber
    );
  };

  const performanceNumber =
    student_performance !== null
      ? parseFloat(student_performance as string)
      : 0;

  const studentCanEligibleForJobApply = () => {
    const studentLogin = isStudentMobileNumber();
    const checkAdmissionStatus = status === AdmisionStatusType.COMPLETED;
    if (studentLogin && checkAdmissionStatus && careerCourse) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const Payload = {
      admission_id: Number(id),
      package_id: Number(package_id),
    };
    if (package_id && id) {
      dispatch(getExamResultDetails(Payload));
    }
  }, [package_id]);

  useEffect(() => {
    if (studentExamState.getExamResultDetails.data) {
      setData(studentExamState.getExamResultDetails.data);
    }
  }, [studentExamState.getExamResultDetails.data]);

  return (
    <>
      <Card className="student-details-card">
        <Row gutter={15} className="gx-align-items-center">
          <Col
            xs={8}
            sm={5}
            md={4}
            lg={4}
            xl={6}
            xxl={4}
            className="gx-text-center"
          >
            <div className="card-profile">
              {image_path ? (
                <Avatar src={<Image src={image_path} />} />
              ) : (
                <Avatar className="avtar-default" icon={<UserOutlined />} />
              )}
            </div>
          </Col>
          <Col xs={14} className="gx-d-sm-none">
            <div className="profile-details">
              <h5 className="usernameMobile">{student_name}</h5>
              <span>GR Id : {gr_id}</span>
            </div>
          </Col>
          <Col
            xs={24}
            sm={18}
            md={20}
            lg={20}
            xl={18}
            xxl={20}
            className="gx-pl-md-0"
          >
            <div className="profile-details mobile-profile">
              <Row>
                <Col
                  xs={24}
                  sm={10}
                  md={6}
                  xl={8}
                  className="gx-d-none gx-d-sm-block"
                >
                  <label>Name</label>
                  <h5 className="gx-mb-0">{student_name}</h5>
                </Col>

                <Col
                  xs={6}
                  sm={6}
                  md={3}
                  xl={4}
                  className="gx-d-none gx-d-sm-block"
                >
                  <label>GR ID</label>
                  <h5 className="gx-mb-0">{gr_id}</h5>
                </Col>

                <Col xs={8} sm={6} md={4} xl={6}>
                  <label>Status</label>
                  <h5 className="gx-mb-0 gx-text-capitalize">
                    {status?.toLowerCase()}
                  </h5>
                </Col>

                <Col
                  xs={10}
                  sm={10}
                  md={4}
                  xl={6}
                  className="gx-mt-sm-3 gx-mt-md-0"
                >
                  <label>Fees</label>
                  <h5 className="gx-mb-0">
                    <i className="fa fa-rupee"></i>{" "}
                    {currencyFormat(Number(fees))}
                  </h5>
                </Col>
                <Col
                  xs={24}
                  sm={14}
                  md={6}
                  xl={8}
                  className="gx-mt-3 gx-mt-md-0 gx-mt-xl-3"
                >
                  <label>Enrollment No.</label>
                  <h5>{enrollment_number}</h5>
                </Col>
                <Col
                  xs={24}
                  sm={10}
                  md={6}
                  xl={10}
                  className="gx-mt-2 gx-mt-sm-3"
                >
                  <label>Course</label>
                  <h5 className="gx-mb-0">{course}</h5>
                </Col>
                {studentCanEligibleForJobApply() && performanceNumber >= 60 && (
                  <Col
                    xs={6}
                    sm={6}
                    md={3}
                    xl={4}
                    className="gx-mt-2 gx-mt-sm-3 gx-mr-sm-3 gx-mr-2 gx-p-sm-0"
                  >
                    <Button
                      className="btn-submit "
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      onClick={() =>
                        openInNewTab(
                          `${placementPortalRegistrationUrl}?gr=${encrypt(
                            gr_id?.toString() || ""
                          )}`
                        )
                      }
                    >
                      Apply for Job
                    </Button>
                  </Col>
                )}
                {data?.credits && (
                  <Col
                    xs={6}
                    sm={6}
                    md={3}
                    xl={4}
                    className="gx-mt-2 gx-mt-sm-3 gx-mr-sm-3 gx-mr-2 gx-p-sm-0"
                  >
                    <Button
                      className="btn-submit "
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      onClick={() => setOpenCountModel(true)}
                    >
                      Result
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>

        {openCountModel && (
          <ModalComponent
            className=""
            title="Result"
            showModal={openCountModel}
            onCancel={() => {
              setOpenCountModel(false);
            }}
            component={
              <>
                <div>
                  {data ? (
                    data.credits?.map((result, index) => (
                      <tr key={index}>
                        <td>
                          <ul>
                            <li>{result.package_name}</li>
                          </ul>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>No Data</p>
                  )}
                </div>
              </>
            }
            footer={[
              <div>
                <Button key="back" onClick={() => setOpenCountModel(false)}>
                  Cancel
                </Button>
              </div>,
            ]}
          ></ModalComponent>
        )}
        {/* <div className="profile-more-details">
            <Row gutter={15}>
            <Col xs={12} md={6} className="gx-px-2">
              <a href="javascript:;" className="profile-option">
              <ClockCircleFilled />
              <h6>Schedule</h6>
              </a>
            </Col>
            <Col xs={12} md={6} className="gx-px-2">
              <a href="javascript:;" className="profile-option">
              <CarryOutFilled />
              <h6>Attendance</h6>
              </a>
            </Col>
            <Col xs={12} md={6} className="gx-px-2 gx-mt-2 gx-mt-md-0">
              <a href="javascript:;" className="profile-option">
              <ReadFilled />
              <h6>Courses</h6>
              </a>
            </Col>
            <Col xs={12} md={6} className="gx-px-2 gx-mt-2 gx-mt-md-0">
              <a href="javascript:;" className="profile-option">
              <ScheduleFilled />
              <h6>Signing Sheet</h6>
              </a>
            </Col>
            </Row>
            <Row gutter={15} className="gx-mt-2 gx-mt-md-3">
            <Col xs={12} md={6} className="gx-px-2">
              <a href="javascript:;" className="profile-option">
              <CreditCardFilled />
              <h6>Fee Details</h6>
              </a>
            </Col>
            <Col xs={12} md={6} className="gx-px-2">
              <a href="javascript:;" className="profile-option">
              <IdcardFilled />
              <h6>Details</h6>
              </a>
            </Col>
            <Col xs={12} md={6} className="gx-px-2 gx-mt-2 gx-mt-md-0">
              <a href="javascript:;" className="profile-option">
              <TrophyFilled />
              <h6>Certificate</h6>
              </a>
            </Col>
            <Col xs={12} md={6} className="gx-px-2 gx-mt-2 gx-mt-md-0">
              <a href="javascript:;" className="profile-option">
              <MessageFilled />
              <h6>Feedback</h6>
              </a>
            </Col>
            </Row>
            </div>
         */}
      </Card>
    </>
  );
};
export default DashboardTableCard;
